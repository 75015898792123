export default {
    DEV_CONFIG: 'DEV_CONFIG',
    API_URL: 'https://dev-animaris.ubiwhere.com',
    API_INVOICING_URL: 'https://dev-animaris.ubiwhere.com/invoicing',
    API_TRIPS_URL: 'https://dev-animaris.ubiwhere.com/trips',
    API_FLEET_URL: 'https://dev-animaris.ubiwhere.com/fleet',
    API_USERS_URL: 'https://dev-animaris.ubiwhere.com/users',
    API_AUTH_URL: 'https://dev-animaris.ubiwhere.com/auth',
    API_TRANS_URL: 'https://dev-animaris.ubiwhere.com/translations',
}
